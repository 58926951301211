.no-shadow-modal {
  height: 100%;
}

.no-shadow-modal > .ant-modal-content {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  height: 100%;
}

.ant-modal-wrap {
  z-index: 1012;
}

.carousel-guida-utente-inner {
  position: relative;
  top: 120px;
}

@media (max-width: 992px) {
  .carousel-guida-utente-inner {
    top: 200px;
  }
}
