.aggiungi-assistito-form {
  span.ant-typography {
    color: #17324d;
  }

  .ant-form-item-explain.ant-form-item-explain-error div {
    display: none;
  }

  .cf {
    &.valid {
      border-color: #d9d9d9 !important;

      &:focus {
        box-shadow: 0 0 0 2px rgba(21, 105, 155, 0.2) !important;
      }
    }

    &.not-valid {
      border-color: #eb3a34;

      &:focus {
        box-shadow: 0 0 0 2px rgba(222, 13, 13, 0.2) !important;
      }
    }
  }

  .ts {
    &.valid {
      border-color: #d9d9d9 !important;

      &:focus {
        box-shadow: 0 0 0 2px rgba(21, 105, 155, 0.2) !important;
      }
    }

    &.not-valid {
      border-color: #eb3a34;

      &:focus {
        box-shadow: 0 0 0 2px rgba(222, 13, 13, 0.2) !important;
      }
    }
  }

  .cp {
    &.valid {
      background: #d9d9d9 !important;
      height: 16px;
    }

    &.not-valid {
      background: #eb3a34;
      height: 16px;
    }
  }

  .error-text {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    color: #eb3a34;
  }
}

#aggiungiForm_codiceFiscaleAssistito {
  text-transform: uppercase;

  &:placeholder-shown {
    text-transform: unset;
  }
}

.ant-switch-handle {
  top: -2.5px;
  position: absolute;
  left: 0px;
  width: 22px;
  height: 22px;
  transition: all 0.2s ease-in-out;

  &::before {
    background: #5e7887;
    border-radius: 50%;
  }
}

.close-icon {
  position: relative;
  left: -20px;
  bottom: 5.5px;
  transition: all 0.2s ease-in-out 0s;
}

.check-icon {
  position: relative;
  left: 22.5px;
  bottom: 5px;
  transition: all 0.2s ease-in-out 0s;
}
