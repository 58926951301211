.card-centri-vaccinali {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 20px;
  background: white;
  cursor: pointer;
  height: 132px;

  &.active {
    border: 1.5px solid #896f17;
  }

  .ant-card-body {
    padding: 15px 30px 30px 30px;
  }

  .card-title {
    color: #5e7887 !important;
    font-size: 18px !important;
    font-family: unset!important;
    margin-bottom: 10px !important;
    font-weight: 700;
  }

  .card-text {
    font-size: 16px !important;
    color: #5e7887 !important;
    font-family: unset!important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 1200px) {
  .card-centri-vaccinali {
    max-width: unset;
    height: unset;
  }
}
