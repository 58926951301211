@import '/src/spin.scss';
$focusColor: #f90;

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;//
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
$customColor: #896f17;
$tableRadius: 6px;

span.text {
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}

#infoCard {
  height: 60vh;
  padding: 100px 100px 100px 20px;
  background-color: whitesmoke;
  text-align: left;
}

.btn-custom {
  background-color: $customColor !important;
  border-color: $customColor !important;
  color: white !important;
  width: 194px;
  height: 40px;

  &:disabled {
    color: #ffffff !important;
    border-color: #d9d9d9 !important;
    background-color: #5e7887 !important;
  }
}

.btn-outline-custom {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 1px $customColor !important;
  color: $customColor !important;
  width: 194px;
  height: 40px;
}

.link-custom {
  color: $customColor !important;
  text-decoration: none;

  &:hover {
    color: $customColor !important;
    text-decoration: underline;
  }

  &:focus-visible {
    color: $customColor !important;
    text-decoration: underline;
    border-color: $focusColor !important;
    box-shadow: 0 0 0 2px $focusColor !important;
    outline: 0;
  }
}

.link-custom-filled,
.link-custom-filled:hover {
  color: white !important;
  text-decoration: none;
}

.table-custom table {
  border-collapse: separate;
  border-spacing: 0px 10px;

  th {
    background-color: whitesmoke !important;
    margin-right: 15px !important;
    border-right: 2px solid white;
    color: #003354 !important;
    border-bottom: none !important;
    font-weight: 600;
    font-size: 16px;

    &:last-child {
      border-right: unset;
    }

    &::before {
      display: none;
    }
  }

  td {
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: $tableRadius;
    height: 71px;

    td:first-child {
      font-weight: 700;
    }
  }

  td:first-child {
    border-top-left-radius: $tableRadius;
    border-bottom-left-radius: $tableRadius;
  }

  td:last-child {
    border-top-right-radius: $tableRadius;
    border-bottom-right-radius: $tableRadius;
  }
}

.table-custom.vaccinazioni {
  tr td:first-child {
    padding-left: 40px;
    text-align: left !important;
  }

  .ant-table-expanded-row-fixed {
    width: unset !important;
  }
}

#infoCardAction {
  padding-top: 40px;
}

.modal-centri-vaccinali {
  height: 639px;

  .ant-modal-body {
    padding-top: 60px !important;
    padding-left: 50px;
    padding-right: 50px;
  }

  .ant-modal-footer {
    border-top: unset;
  }
}

@media (max-width: 576px) {
  .modal-centri-vaccinali {
    top: 5px;

    .ant-modal-body {
      padding-top: 24px !important;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .centri-vaccinali-scrollable-section {
    height: 155px !important;
  }

  .leaflet-container {
    min-height: 180px !important;
  }

  h3 {
    margin: auto;
  }
}

.custom-divider {
  border-top: 2px solid #e5e5e5;
  padding-top: 20px;
}

.centri-vaccinali-scrollable-section {
  height: 336px;
  overflow: hidden;
  overflow-y: scroll;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #e5e5e5;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar {
  width: 5px;
  background-color: #e5e5e5;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #5e7887;
}

.table-row-dark {
  background-color: #f4f5f6;
}

.table-row-light {
  background-color: white;
}

.circle-justify-center {
  width: 80px;
  height: 80px;
  background-color: #d4c207;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &.aggiungi-assistito {
    background-color: #896f17;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #896f17;
}

.ant-radio-inner {
  border: 1px solid #5c6f82;
}

.ant-radio-inner::after {
  background-color: #896f17;
  width: 14px;
  height: 14px;
  display: block;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #896f17;
}

span.ant-radio-inner {
  width: 22px;
  height: 22px;
}

.ant-table-cell.ant-table-selection-column {
  border-right: unset;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.logo svg {
  width: 100%;
  height: 100%;
}

.ant-switch-checked > .ant-switch-handle::before {
  background-color: #896f17;
}

.ant-switch-checked {
  background-color: rgba(128, 128, 128, 0.25);
}

.btn-search {
  background-color: #896f17 !important;
  border-color: #896f17 !important;
  color: white !important;
  width: 96px;
  height: 40px;
  margin-left: 30px;
}

#formSearch {
  label {
    strong {
      font-size: 16px;
      color: #17324d;
    }
  }
}

.card-warning {
  border: 1px solid #d97e00;
  margin-top: 45px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  text-align: left;

  .card-inner-text:nth-child(1) {
    padding: 20px 55px 0px 55px;
  }

  .card-inner-text:nth-child(2) {
    padding: 0px 55px 20px 55px;
  }
}

.card-title-left {
  position: relative;
  top: 59px;
  width: 215px;
  white-space: nowrap;
  background: white;
  margin-left: 20px;
  z-index: 20;

  span {
    text-transform: uppercase;
    margin-left: 12px;
    color: #d97e00;
  }
}

.ant-select-selection-item {
  color: #5e7887;
  padding: 8px 20px 8px 0px;
  text-align: left;
  align-self: center;
}

.ant-select-selector-item {
  height: 40px;
}

#stato {
  .ant-select-selector {
    height: 40px;
  }

  .ant-select-arrow {
    color: #5e7887;
  }
}

.custom-collapse-header > .ant-collapse-item > .ant-collapse-header {
  padding-top: 32px;
  padding-bottom: 50px;
  padding-left: 45px;
}

.custom-collapse-header > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] {
  padding-bottom: 20px;
}

.custom-collapse-header > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 45px;
  padding-bottom: 45px;
}

.dose {
  &-non-completata {
    margin-left: 20px;
    background: white;
    border-color: #fbb800;
    color: #5c6f82;
  }

  &-completata {
    margin-left: 20px;
    background: white;
    border-color: #009a6d;
    color: #5c6f82;
  }
}

.table-dosi table {
  border-collapse: separate;
  border-spacing: 0px 10px;

  th {
    background-color: whitesmoke !important;
    margin-right: 15px !important;
    //border-bottom: 10px solid white !important;
    border-right: 2px solid white;
    color: #003354 !important;
    border-bottom: none !important;
    font-weight: 600;
    font-size: 16px;
  }

  td {
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr.ant-table-row {
    border-radius: $tableRadius;
    height: 71px;

    td {
      border-bottom: 1px solid #baccd9 !important;
      border-top: 1px solid #baccd9;

      &:first-child {
        border-left: 1px solid #baccd9;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-right: 1px solid #baccd9;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.filter-form {
  display: inline-flex;
  align-items: flex-end;

  .btn-search {
    margin-left: unset;
  }
}

@media (max-width: 767px) {
  .filter-form {
    display: contents;
    align-items: flex-end;
  }

  .select-filter {
    margin-left: unset !important;
  }

  #stato {
    .ant-form-item-label {
      padding-left: unset !important;
    }
  }
}

td.ant-table-cell:empty {
  &::after {
    content: '-';
    text-align: center;
  }
}

.ant-table-cell {
  text-align: center !important;
}

.modal-utente {
  overflow: hidden;

  .ant-modal-close {
    display: none;
  }

  .ant-carousel .space-dots {
    .ant-space-item {
      display: flex;
      margin-bottom: 11px;
    }

    li {
      border-radius: 50%;
      width: 10px;
      height: 10px;

      button {
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.ant-spin-text {
  color: $customColor;
  display: block;
}

@media (max-width: 768px) {
  .btn-download {
    margin-top: 20px;
  }
}

.image-carousel {
  height: 100%;

  @media (max-width: 800px) {
    width: 920px !important;
  }
}

.divider {
  border-top: 2px solid #e5e5e5;
  margin-top: 16px;

  &-search {
    margin-bottom: 30px;
  }

  &-search-filter {
    margin-bottom: 40px;
  }
}

.ant-empty-normal {
  .ant-empty-description {
    color: #17324d !important;
  }
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.button-guida-utente {
  position: relative;
  bottom: 20px;
  right: 20px;
  z-index: 20;
  display: flex;
  justify-content: end;
}

@media (max-width: 1128px) {
  .button-guida-utente {
    position: relative;
    bottom: -20px;
    right: 20px;
    z-index: 20;
    display: flex;
    justify-content: end;
  }
}

@media (max-width: 425px) {
  /*.ant-space {
    flex-direction: column;
  }*/

  .border-notification {
    display: none;
  }
}

@media (max-width: 300px) {
  .ant-space {
    flex-direction: column;
  }

  .modal-utente .ant-space {
    flex-direction: unset;
  }
}

button:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.ant-radio-input:focus-visible + .ant-radio-inner {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.ant-breadcrumb-link:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.border-notification {
  border-left: 4px solid #0066cc;
  height: 50px;
}

.anticon.anticon-close:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.goToAssisted:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

.sr-only {
  display: none !important;
}

.ant-card.card-centri-vaccinali:focus-visible {
  border-color: $focusColor !important;
  box-shadow: 0 0 0 2px $focusColor !important;
  outline: 0;
}

#aggiungiForm_codiceTesseraSanitariaAssistito {
  @media (max-width: 354px) {
    flex-direction: column;
  }
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-carousel .slick-initialized .slick-slide {
  @media (max-width: 800px) {
    overflow-x: scroll;
  }
}