.box-dati-assistito {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 21px;
  margin-bottom: 40px;

  .ant-row {
    word-break: break-word;

    .ant-col {
      display: flex;
      justify-content: flex-start;

      .ant-space {
        text-align: left;

        .ant-space-item:first-child {
          .ant-typography {
            color: #003354;
          }
        }
      }
    }
  }
}